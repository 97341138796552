<script>
import EventsService from '@/service/events.service';
import dayJs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';

export default {
  data() {
    return {
      polling: null,
      loading: false,
      showDateMenu: false,
      searchDate: [],
      searchDateString: '',
      searchStatus: undefined,
      totalItems: 0,
      options: {
        itemsPerPage: 30,
        sortBy: ['date'],
        sortDesc: [true],
        search: '',
      },

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Nome', value: 'customer.name', sortable: false },
        { text: 'Dispositivo', value: 'device.name', sortable: false },
        { text: 'Data', value: 'date' },
        { text: 'Status', value: 'authorized', sortable: false, align: 'center' },
      ],

      items: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },

      deep: true,
    },

    searchDate: {
      handler() {
        this.fetch();
      },
    },

    searchStatus: {
      handler() {
        this.fetch();
      },
    },
  },

  created() {
    this.pollData();
  },

  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.fetch();
      }, 15000);
    },

    async fetch() {
      this.loading = true;

      try {
        const params = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          search: this.options.search,
        };

        params['filter.device.accessType'] = 'LUNCH';

        if (this.searchDate[0]) {
          let searchDate = cloneDeep(this.searchDate);
          this.searchDateString = `${this.formatDate(searchDate[0])}`;
          params['filter.date'] = `$btw:${searchDate[0]} 00:00:00.001,${searchDate[0]} 23:59:59.999`;

          if (this.searchDate[1]) {
            searchDate = searchDate.sort((a, b) => {
              return new Date(a) - new Date(b);
            });
            this.searchDateString = `${this.formatDate(searchDate[0])} ~ ${this.formatDate(searchDate[1])}`;
            params['filter.date'] = `$btw:${searchDate[0]} 00:00:00.001,${searchDate[1]} 23:59:59.999`;
          }
        }

        if (this.searchStatus && this.searchStatus !== 'Todos') {
          params['filter.authorized'] = `$eq:${this.searchStatus === 'Autorizado'}`;
        }

        if (this.options.sortBy.length > 0) {
          params.sort = `${this.options.sortBy[0]},${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`;
        }

        const res = await EventsService.find(params);
        this.items = res.data;
        this.page = res.meta.currentPage;
        this.totalItems = res.meta.totalItems;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    getStatusOptions(status) {
      let text = status ? 'Autorizado' : 'Negado';
      let color = 'white';
      let background = 'black';

      if (status) {
        color = 'success lighten-3';
        background = 'success darken-3';
      } else {
        color = 'error lighten-3';
        background = 'error darken-3';
      }

      return {
        text,
        color,
        background,
      };
    },

    formatDate(date) {
      if (!date) return null;
      return dayJs(date).format('DD/MM/YY');
    },

    beforeDestroy() {
      clearInterval(this.polling);
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <h4 class="pb-1">Registro de merendas</h4>
    <v-card>
      <v-card-text>
        <v-row class="mb-0">
          <v-col cols="7">
            <v-text-field
              v-model="options.search"
              append-icon="mdi-magnify"
              label="Pesquisar por nome ou dispositivo"
              color="white"
              hide-details="auto"
              outlined
              dense
            />
          </v-col>
          <v-col cols="3">
            <v-menu
              v-model="showDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="searchDateString"
                  append-icon="mdi-calendar"
                  label="pesquisar data"
                  readonly
                  color="white"
                  hide-details="auto"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="searchDate" range no-title scrollable></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="searchStatus"
              :items="[
                {
                  text: 'Todos',
                },
                {
                  text: 'Autorizado',
                },
                {
                  text: 'Negado',
                },
              ]"
              label="Status"
              color="white"
              hide-details="auto"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [10, 30, 50, 100] }"
          class="no-line-row"
        >
          <template v-slot:[`item.customer.name`]="{ item }">
            {{ item.customer && item.customer.name | empty }}
          </template>
          <template v-slot:[`item.device.name`]="{ item }">
            {{ item.device && item.device.name | empty }}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ new Date(item.date) | dateFormat('DD/MM/YYYY HH:mm:ss') }}
          </template>
          <template v-slot:[`item.authorized`]="{ item }">
            <v-chip
              :color="getStatusOptions(item.authorized).background"
              :text-color="getStatusOptions(item.authorized).color"
              class="justify-center"
              :style="{ width: '110px' }"
            >
              {{ getStatusOptions(item.authorized).text }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<style></style>
