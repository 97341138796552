var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('h4',{staticClass:"pb-1"},[_vm._v("Registro de merendas")]),_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mb-0"},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar por nome ou dispositivo","color":"white","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","label":"pesquisar data","readonly":"","color":"white","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.searchDateString),callback:function ($$v) {_vm.searchDateString=$$v},expression:"searchDateString"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDateMenu),callback:function ($$v) {_vm.showDateMenu=$$v},expression:"showDateMenu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},model:{value:(_vm.searchDate),callback:function ($$v) {_vm.searchDate=$$v},expression:"searchDate"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":[
              {
                text: 'Todos',
              },
              {
                text: 'Autorizado',
              },
              {
                text: 'Negado',
              },
            ],"label":"Status","color":"white","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.searchStatus),callback:function ($$v) {_vm.searchStatus=$$v},expression:"searchStatus"}})],1)],1),_c('v-data-table',{staticClass:"no-line-row",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [10, 30, 50, 100] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.customer.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("empty")(item.customer && item.customer.name))+" ")]}},{key:`item.device.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("empty")(item.device && item.device.name))+" ")]}},{key:`item.date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(item.date),'DD/MM/YYYY HH:mm:ss'))+" ")]}},{key:`item.authorized`,fn:function({ item }){return [_c('v-chip',{staticClass:"justify-center",style:({ width: '110px' }),attrs:{"color":_vm.getStatusOptions(item.authorized).background,"text-color":_vm.getStatusOptions(item.authorized).color}},[_vm._v(" "+_vm._s(_vm.getStatusOptions(item.authorized).text)+" ")])]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }